import { ContentTeaseEntry } from "frontend/contentful/schema/primitives";
import { ArticleTeaseProps } from "design-system/components/primitives/article-tease/article-tease";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { createMediaAssetProps } from "../../primitives/media-asset.props";
import { RenderContentfulRichText } from "../../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";

export const createContentTeaseProps = createContentfulProps<
  ContentTeaseEntry,
  ArticleTeaseProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, getEntry } = useContentful();
  const mediaAsset = createMediaAssetProps(entry.fields.media);

  // force 4:3 aspect ratio
  if (mediaAsset?.image) {
    mediaAsset.image.width = "600px";
    mediaAsset.image.height = "450px";
  }
  if (mediaAsset?.kaltura) {
    mediaAsset.kaltura.withModal = true;
  }

  return {
    type: "Article",
    title: (
      <RenderContentfulRichText
        document={entry.fields?.title}
        disableParagraphs
      />
    ),
    link: getEntryUrl(entry.fields?.link),
    ariaLabel: getEntry(entry.fields.link)?.fields.ariaLabel,
    tease: <RenderContentfulRichText document={entry.fields?.description} />,
    image: mediaAsset?.image,
    video: mediaAsset?.video,
    kaltura: mediaAsset?.kaltura,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      tease: createEditAttributes({ entry: entry.fields.link, fieldId: "url" }),
      description: createEditAttributes({ entry, fieldId: "description" }),
    },
  };
});
